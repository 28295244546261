import React, { useEffect, useRef, useState } from 'react'
import { MenuContainer } from "./styles";
import PrimeraPortada from "../../images/arquitectura/1er-ano_portada-min.jpg";
import SegundaPortada from "../../images/arquitectura/2do-ano_portada-min.jpg";
import TerceraPortada from "../../images/arquitectura/3er-ano_portada-min.jpg";
import CuartaPortada from "../../images/arquitectura/4to-ano_portada-min.jpg";
import QuintaPortada from "../../images/arquitectura/5to-ano_portada-min.jpg";
import NivelacionPortada from "../../images/arquitectura/Nivelacion_portada-min.jpg";
import Modal from "./Modal.jsx";

const Menu = () => {
  const EntranceMenuContainer = useRef(null);
  const Title = useRef(null)
  const Button1 = useRef(null);
  const Button2 = useRef(null);
  const Button3 = useRef(null);
  const Button4 = useRef(null);
  const Button5 = useRef(null);
  const Button6 = useRef(null);

  const [ModalDisplay, setModalDisplay] = useState({
    first: false,
    second: false,
    third: false,
    quarter: false,
    fifth: false,
    sixth: false
  })

  useEffect(() => {
    setTimeout(() => {
      if(Title){
        Title.current.className = `${Title.current.className} TextTitleAnimation`
      }
    }, 7000);
    setTimeout(() => {
      if(Button1){
        Button1.current.className = `${Button1.current.className} FadeInButton`
        Button6.current.className = `${Button1.current.className} FadeInButton`
      }
    }, 8000);
    setTimeout(() => {
      if(Button2){
        Button2.current.className = `${Button2.current.className} FadeInButton`
        Button5.current.className = `${Button2.current.className} FadeInButton`
      }
    }, 8500);
    setTimeout(() => {
      if(Button3){
        Button3.current.className = `${Button3.current.className} FadeInButton`
        Button4.current.className = `${Button3.current.className} FadeInButton`
      }
    }, 9000);
  }, [])


  return (
    <MenuContainer
      ref={EntranceMenuContainer}
    >
      <div className="menu-subcontainer">
        <h2 className="animated-h2" ref={Title}>ARQUITECTURAS DE LA IMAGINACIÓN UPC</h2>
        <h2 className="h2-empty">ARQUITECTURAS DE LA IMAGINACIÓN UPC</h2>
        <div className="buttons-container">
          <button
            ref={Button1}
            onClick={() => setModalDisplay({ ...ModalDisplay, first: !ModalDisplay.first })}
          >
            <img src={NivelacionPortada} alt="arquitecturas de la imaginación de UPC - Nivelación" />
            <div className="arquitectura-text" >
              <h3>NIVELACIÓN</h3>
            </div>
          </button>
          <button
            ref={Button2}
            onClick={() => setModalDisplay({ ...ModalDisplay, second: !ModalDisplay.second })}
          >
            <img src={PrimeraPortada} alt="arquitecturas de la imaginación de UPC - Primer año" />
            <div className="arquitectura-text">
              <h3>PRIMER AÑO</h3>
            </div>
          </button>
          <button
            ref={Button3}
            onClick={() => setModalDisplay({ ...ModalDisplay, third: !ModalDisplay.third })}
          >
            <img src={SegundaPortada} alt="arquitecturas de la imaginación de UPC - Segundo año" />
            <div className="arquitectura-text">
              <h3>SEGUNDO AÑO</h3>
            </div>
          </button>
          <button
            ref={Button4}
            onClick={() => setModalDisplay({ ...ModalDisplay, quarter: !ModalDisplay.quarter })}
          >
            <img src={TerceraPortada} alt="arquitecturas de la imaginación de UPC - Tercer año" />
            <div className="arquitectura-text">
              <h3>TERCER AÑO</h3>
            </div>
          </button>
          <button
            ref={Button5}
            onClick={() => setModalDisplay({ ...ModalDisplay, fifth: !ModalDisplay.fifth })}
          >
            <img src={CuartaPortada} alt="arquitecturas de la imaginación de UPC - Cuarto año" />
            <div className="arquitectura-text">
              <h3>CUARTO AÑO</h3>
            </div>
          </button>
          <button
            ref={Button6}
            onClick={() => setModalDisplay({ ...ModalDisplay, sixth: !ModalDisplay.sixth })}
          >
            <img src={QuintaPortada} alt="arquitecturas de la imaginación de UPC - Quinto año" />
            <div className="arquitectura-text">
              <h3>QUINTO AÑO</h3>
            </div>
          </button>
        </div>
      </div>
      <Modal
        isOpen={ModalDisplay.first}
        toggle={() => setModalDisplay({ ...ModalDisplay, first: !ModalDisplay.first })}
      >
        <button onClick={() => setModalDisplay({ ...ModalDisplay, first: !ModalDisplay.first })} className="title">
          <h2>ARQUITECTURAS DE LA IMAGINACIÓN UPC<br />[NIVELACIÓN]</h2>
          <h3>X</h3>
        </button>
        <div className="container-iframe">
          <iframe
            allowfullscreen="true"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            src='https://art.kunstmatrix.com/apps/artspaces/?external=true&splashscreen=true&language=en&uid=58146&exhibition=7356088'
            title='Arquitecturas de la Imaginación [Nivelación]'
          />
        </div>
      </Modal>
      <Modal
        isOpen={ModalDisplay.second}
        toggle={() => setModalDisplay({ ...ModalDisplay, second: !ModalDisplay.second })}
      >
        <button onClick={() => setModalDisplay({ ...ModalDisplay, second: !ModalDisplay.second })} className="title">
          <h2>ARQUITECTURAS DE LA IMAGINACIÓN UPC<br />[PRIMER AÑO]</h2>
          <h3>X</h3>
        </button>
        <div className="container-iframe">
          <iframe
            allowfullscreen="true"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            src='https://art.kunstmatrix.com/apps/artspaces/?external=true&splashscreen=true&language=en&uid=58146&exhibition=7345001'
            title='Arquitecturas de la Imaginación [Primer Año]'
          />
        </div>
      </Modal>
      <Modal
        isOpen={ModalDisplay.third}
        toggle={() => setModalDisplay({ ...ModalDisplay, third: !ModalDisplay.third })}
      >
        <button onClick={() => setModalDisplay({ ...ModalDisplay, third: !ModalDisplay.third })} className="title">
          <h2>ARQUITECTURAS DE LA IMAGINACIÓN UPC<br />[SEGUNDO AÑO]</h2>
          <h3>X</h3>
        </button>
        <div className="container-iframe">
          <iframe
            allowfullscreen="true"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            src='https://art.kunstmatrix.com/apps/artspaces/?external=true&splashscreen=true&language=en&uid=58146&exhibition=7355427'
            title='Arquitecturas de la Imaginación [Segundo Año]'
          />
        </div>
      </Modal>
      <Modal
        isOpen={ModalDisplay.quarter}
        toggle={() => setModalDisplay({ ...ModalDisplay, quarter: !ModalDisplay.quarter })}
      >
        <button onClick={() => setModalDisplay({ ...ModalDisplay, quarter: !ModalDisplay.quarter })} className="title">
          <h2>ARQUITECTURAS DE LA IMAGINACIÓN UPC<br />[TERCER AÑO]</h2>
          <h3>X</h3>
        </button>
        <div className="container-iframe">
          <iframe
            allowfullscreen="true"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            src="https://art.kunstmatrix.com/apps/artspaces/?external=true&splashscreen=true&language=en&uid=58146&exhibition=7355769"
            title='Arquitecturas de la Imaginación [Tercer Año]'
          />
        </div>
      </Modal>
      <Modal
        isOpen={ModalDisplay.fifth}
        toggle={() => setModalDisplay({ ...ModalDisplay, fifth: !ModalDisplay.fifth })}
      >
        <button onClick={() => setModalDisplay({ ...ModalDisplay, fifth: !ModalDisplay.fifth })} className="title">
          <h2>ARQUITECTURAS DE LA IMAGINACIÓN UPC<br />[CUARTO AÑO]</h2>
          <h3>X</h3>
        </button>
        <div className="container-iframe">
          <iframe
            allowfullscreen="true"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            src="https://art.kunstmatrix.com/apps/artspaces/?external=true&splashscreen=true&language=en&uid=58146&exhibition=7362162"
            title='Arquitecturas de la Imaginación [Cuarto Año]'
          />
        </div>
      </Modal>
      <Modal
        isOpen={ModalDisplay.sixth}
        toggle={() => setModalDisplay({ ...ModalDisplay, sixth: !ModalDisplay.sixth })}
      >
        <button onClick={() => setModalDisplay({ ...ModalDisplay, sixth: !ModalDisplay.sixth })} className="title">
          <h2>ARQUITECTURAS DE LA IMAGINACIÓN UPC<br />[QUINTO AÑO]</h2>
          <h3>X</h3>
        </button>
        <div className="container-iframe">
          <iframe
            allowfullscreen="true"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
            src="https://art.kunstmatrix.com/apps/artspaces/?external=true&splashscreen=true&language=en&uid=58146&exhibition=7353022"
            title='Arquitecturas de la Imaginación [Quinto Año]'
          />
        </div>
      </Modal>
    </MenuContainer>
  )
}

export default Menu
