import React from 'react'
import {
  ModalArquitectura
} from "./styles";

const Modal = ({ toggle, children, isOpen }) => {
  return (
    <ModalArquitectura backdrop={true} show={isOpen} centered={true} onHide={toggle}>
      {children}
    </ModalArquitectura>
  )
}

export default Modal
