import styled from '@emotion/styled';
import Modal from 'react-bootstrap/Modal';

export const ModalArquitectura = styled(Modal)`
  & button{
    text-align: left;
    padding: 0.5rem 2em;
    background: unset;
    border: unset;
    outline: none !important;
    position: relative;
    display: block;
  }
  & h2 {
    margin-bottom:0;
    color: #fff;
    margin-top: 4px;
    font-size: 2rem;
    width: 95%;
    font-family: 'SolanoGothicMVBStd-Bd';
  }
  & h3 {
    position: absolute;
    font-family: 'SolanoGothicMVBStd-Bd';
    color: #fff;
    font-size: 2rem;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    width: 30px;
  }
  & .modal-content .title{
    background: #e30713;
  }

  & iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  & .container-iframe{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 80vh;
    padding-top: 56.25%;
  }
`

export const MenuContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content:center;
  align-items: center;
  top: 0;
  right: 0;
  pointer-events: none;
  & .menu-subcontainer {
    //width: 55%;
    width: 100vh;
    max-width: 80vw;
    //max-width: 750px;
  }
  & h3{
    font-family: 'SolanoGothicMVBStd-Bd';
    color: #e30713;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    user-select: 0;
    pointer-events: none;
    transition: 1s opacity ease;
  }
  & .buttons-container button .arquitectura-text{
    position: absolute;
    width: 100%;
    background: #ffffff00;
    height: 100%;
    top: 0;
    transition: 0.5s background-color ease;
  }
  & .buttons-container button:hover .arquitectura-text{
    background-color: #ffffffc7;
  }
  & .buttons-container button:hover h3{
    opacity: 1;
  }


  & .buttons-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & .buttons-container button{
    pointer-events: all;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 0.9rem;
    transform: rotateX(0deg) scale(1);
    border: 1px solid black;
    transition: 1.5s box-shadow ease, .8s transform ease, 2s border ease;
  }
  & .buttons-container button:hover{
    box-shadow: 0px 10px 16px #555555a6;
    border: 1px solid gray;
    transform: rotate(0deg) scale(1.1) translateY(-10px);
  }

  & button{
    width: 32%;
    //margin-top: 4%;
    opacity: 0;
    border: 0;
    background: unset;
    outline: none !important;
  }
  & h2{
    width: 100%;
    text-align: center;
    color: #e30713;
    line-height: 1;
  }
  & .h2-empty{
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }
  & .animated-h2{
    position: absolute;
    top: -100vh;
  }
  @media only screen and (max-width: 1350px) {
    & .menu-subcontainer {
      //max-width: 550px;
      //margin-left: 50px;
      //margin-right: 50px;
    }
    & h2 {
      font-size: 1.8rem;
    }
  }
  @media only screen and (max-width: 991px){
    & .menu-subcontainer {
      max-width: 650px;
      width: 65%;
    }
  }
  @media only screen and (max-width: 630px) {
    & button {
      width: 40%;
    }
  }


  @media only screen and (max-width: 550px) {
    margin-top: 85px;
    height: unset;
    & .menu-subcontainer{
      width: 80%;
    }
    & .buttons-container button {
      width: 60%;
      margin: auto;
    }
    & .buttons-container button{
      transform: rotateX(0deg) scale(0.9);
    }
    & .buttons-container button:hover{
      transform: rotate(0deg) scale(1) translateY(-10px);
    }
    & h2{
      width: 75%;
      margin: auto;
      font-size: 6vw;
    }
    & .TextTitleAnimation {
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  @media only screen and (max-width: 450px) {
    & .buttons-container button {
      width: 65%;
      margin: auto;
    }
    & h2{
      width: 100%;
      margin: auto;
      font-size: 8vw;
    }
  }
  @media only screen and (max-width: 400px) {
    & .buttons-container button {
      width: 75%;
      margin: auto;
    }
  }
`

export const ParticleAnimationContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: ${props => props.showoverflow ? 'hidden' : 'visible' };
  & h1{
    font-family: 'SolanoGothicMVBStd-Bd';
  }
  & h2{
    font-family: 'SolanoGothicMVBStd-Bd';
  }
  & #tsparticles{
    height: 100vh;
    position: absolute;
    width: 100vw;
  }
  @media only screen and (max-width: 550px) {
    & #tsparticles{
      height: 150vh;
    }
    & h1{
      font-size: 10vw;
    }
  }
  & #tsparticles > canvas{
    height: 100vh;
  }
  @keyframes FadeAnimationIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes OutAnimationLeft {
    0% {
      width:100vw;
      opacity: 1;
    }
    100% {
      width:0vw;
      opacity: 0;
    }
  }
  @keyframes OutAnimationRight {
    0% {
      width:100vw;
      opacity: 1;
    }
    100% {
      width:0vw;
      opacity: 0;
    }
  }
  @keyframes OutAnimationTop {
    0% {
      height:100vh;
      opacity: 1;
    }
    100% {
      height:0vh;
      opacity: 0;
    }
  }
  @keyframes TextAnimationTop {
    0% {
      top: 100vh;
      opacity: 0;
    }
    100% {
      top: 0vh;
      opacity: 1;
    }
  }
  @keyframes TextAnimationOutBot {
    0% {
      top: 0%;
      opacity: 1;
    }
    100% {
      top: -100%;
      opacity: 0;
    }
  }
  @keyframes TextTitleAnimation {
    0% {
      top: -200vh;
    }
    100% {
      top: 0vh;
    }
  }

  & .OutLeft{
    animation: OutAnimationLeft ease 2s;
    animation-fill-mode: forwards
  }
  & .OutRight{
    animation: OutAnimationRight ease 2s;
    animation-fill-mode: forwards
  }
  & .OutTop{
    animation: OutAnimationTop ease 2s;
    animation-fill-mode: forwards
  }
  & .TextTop{
    animation: TextAnimationTop ease 2s;
    animation-fill-mode: forwards
  }
  & .TextOutBottom{
    animation: TextAnimationOutBot ease 2s;
    animation-fill-mode: forwards
  }
  & .FadeInButton{
    animation: FadeAnimationIn ease 2s;
    animation-fill-mode: forwards
  }
  & .TextTitleAnimation{
    animation: TextTitleAnimation ease 2s;
    animation-fill-mode: forwards
  }
`

export const AnimationEntrance = styled.div`
  width: 100vw;
  height: 100vh;
  background: #000;
  position: absolute;
  z-index: 10;
  opacity: 1;
`
export const AnimationEntrance2 = styled.div`
  width: 100vw;
  height: 100vh;
  background: #000;
  right:0;
  position: absolute;
  z-index: 10;
  opacity: 1;
`
export const TextEntrance = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  opacity: 1;
  top: ${props => props.displayed ? '0%' : '-100%' };

  & h1{
    width: 90%;
    text-align: center;
    color: #e30713;
  }
`
